<template>
  <promised :promise="promise">
    <template #pending>
      <b-loading 
        :active="true"
        :is-full-page="false"
      />
    </template>
    <template #default="{ data }">
      <div>
        <breadcrumb :items="breadcrumbs" />
        <hero>
          Dashboard
          <switch-locale
            slot="right" 
            v-model="locale"
            @change="handleLocaleChange"
          />
        </hero>
        <section class="section is-main-section">
          <card
            title="Dashboard"
            icon="desktop-mac"
          >
            <scheduler 
              :data="data"
              :locale="locale"
            />
          </card>
        </section>
      </div>
    </template>
    <template #rejected>
      <section class="section is-main-section">
        <div class="content has-text-grey has-text-centered">
          <b-icon 
            icon="emoticon-sad" 
            size="is-large" 
          />
          <p>Something went wrong</p>
        </div>
      </section>
    </template>
  </promised>
</template>

<script>
import { mapGetters } from "vuex";
import { Promised } from "vue-promised";
import availablelocales from "@/common/locales";
import Breadcrumb from "@/components/Breadcrumb";
import Hero from "@/components/Hero";
import SwitchLocale from "@/components/SwitchLocale";
import Card from "@/components/Card";
import Scheduler from "@/components/Scheduler";
import Repository from "@/repositories/Repository";

export default {
  name: 'Dashboard',

  components: {
    Promised,
    Breadcrumb,
    Hero,
    SwitchLocale,
    Card,
    Scheduler,
  },

  data() {
    return {
      breadcrumbs: [
        'Admin', 
        'Dashboard',
      ],
      locale: '',
    };
  },

  computed: {
    ...mapGetters('config', ['defaultApiLocale']),

    promise() {
      return Repository.get('games').findByScheduled({
        language: this.locale,
        state: 'preview',
      });
    },
  },

  created() {
    this.locale = (this.$route.query.language && availablelocales.find(locale => locale.code === this.$route.query.language)) ? this.$route.query.language : this.defaultApiLocale;
  },

  methods: {
    handleLocaleChange(locale) {
      this.locale = locale;
    },
  }
};
</script>
