<template>
  <div>
    <vue-scheduler
      :events="events"
      :min-date="minDate"
      :max-date="maxDate"
      :disable-dialog="true"
      @event-clicked="eventClicked"
    />
  </div>
</template>

<script>
import Vue from "vue";
import VueScheduler from "v-calendar-scheduler";
import moment from "moment";

Vue.use(VueScheduler, {
  availableViews: [],
  eventDisplay: 'title',
});

export default {
  name: 'Scheduler',

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    locale: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      minDate: moment().startOf('month'),
      maxDate: moment().add(1, 'Y'),
    };
  },

  computed: {
    events() {
      return this.data.map((data) => ({
          date: data.published_at,
          title: data.title,
          distribution_id: data.distribution_id,
        }));
    },
  },
  
  methods: {
    eventClicked(event) {
      this.$router.push({ 
        name: 'GamesEdit', 
        params: { 
          game: event.distribution_id,
        },
        query: {
          language: this.locale,
        },
      });
    },
  }
};
</script>

<style>
@import "~v-calendar-scheduler/lib/main.css";
</style>
